'use strict';

function UID(str) {
    let uid = location.host.split(".")
    if (uid.length > 2 && location.host !== "ns.ttq.so") {
        uid = uid[0]
    } else {
        const href = new URL(location.href);
        uid = href.searchParams.get(str).split("/")[0]
    }
    return uid ? uid.toUpperCase() : null
}

module.exports = UID;



